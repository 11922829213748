import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaHome,
  FaLaptopHouse,
  FaClock,
  FaRegMoneyBillAlt,
  FaWpforms,
  FaUsers,
  FaTh,
  FaExclamation,
  FaUserClock
} from "react-icons/fa";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { useSelector } from 'react-redux';

const SideNav = () => {

  // getting value of authenticated user using redux persistor
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const hrPortalState = useSelector((state) => state.HrPortaReducer)

  const { employeeName, role } = AuthReducer.data;
  // console.log("🚀 ~ file: SideNav.js:18 ~ SideNav ~ role", role)
  let path = window.location.pathname;
  path = path.split("/");
  let activePath = path[path.length - 1];
  // console.log("🚀 ~ file: SideNav.js:18 ~ SideNav ~ activePath", activePath)

  return (
    <>
      <SidebarMenu>
        <SidebarMenu.Header className="userInfo">
          <SidebarMenu.Brand className="position-relative">
            <img
              src={require("../assets/images/avatar.png")}
              alt="Brand"
              className="userAvatar"
              draggable="false"
            />
            <span className="activeDot"></span>
          </SidebarMenu.Brand>
          <h5>{employeeName || ""}</h5>
          <span>{role || ""}</span>
        </SidebarMenu.Header>
        <SidebarMenu.Body className="px-2">
          <SidebarMenu.Nav>
            {role === "hr" ? (
              hrPortalState === "showHrPortal" ? (
                <>
                  <NavLink to="/hr-dashboard" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaHome className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Hr Dashboard</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/employees" className={activePath === "employees" || activePath === "add-employee" || activePath === "edit-employee" || activePath === "view-employee" ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaUsers className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Employees</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/department" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaTh className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Department</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/emp-attendance" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaUserClock className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Attendance</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/emp-overview" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaUserClock className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Attendance Overview</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/notice-board" className={activePath === "notice-board" || activePath === "create-announcement" ? "navLink active" : "navLink"}>
                    <SidebarMenu.Nav.Icon>
                      <FaExclamation className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle" >Notice Board</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/leaves-management" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaWpforms className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle" >Leaves Management</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/leaves-application" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaWpforms className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Leaves Application</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink to="/dashboard" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaHome className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Dashboard</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/attendance" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaClock className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Attendance</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/myleaves" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaLaptopHouse className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">My Leaves</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/applyLeaves" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaWpforms className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Apply Leaves</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                  <NavLink to="/Payslip" className="navLink">
                    <SidebarMenu.Nav.Icon>
                      <FaRegMoneyBillAlt className="me-2 navIco" />
                      <SidebarMenu.Nav.Title className="sidebarNavTitle">Payslips</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Icon>
                  </NavLink>
                </>
              )

            ) : (
              <>
                <NavLink to="/dashboard" className="navLink">
                  <SidebarMenu.Nav.Icon>
                    <FaHome className="me-2 navIco" />
                    <SidebarMenu.Nav.Title className="sidebarNavTitle">Dashboard</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Icon>
                </NavLink>
                <NavLink to="/attendance" className="navLink">
                  <SidebarMenu.Nav.Icon>
                    <FaClock className="me-2 navIco" />
                    <SidebarMenu.Nav.Title className="sidebarNavTitle">Attendance</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Icon>
                </NavLink>
                <NavLink to="/myleaves" className="navLink">
                  <SidebarMenu.Nav.Icon>
                    <FaLaptopHouse className="me-2 navIco" />
                    <SidebarMenu.Nav.Title className="sidebarNavTitle">My Leaves</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Icon>
                </NavLink>
                <NavLink to="/applyLeaves" className="navLink">
                  <SidebarMenu.Nav.Icon>
                    <FaWpforms className="me-2 navIco" />
                    <SidebarMenu.Nav.Title className="sidebarNavTitle">Apply Leaves</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Icon>
                </NavLink>
                <NavLink to="/Payslip" className="navLink">
                  <SidebarMenu.Nav.Icon>
                    <FaRegMoneyBillAlt className="me-2 navIco" />
                    <SidebarMenu.Nav.Title className="sidebarNavTitle">Payslips</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Icon>
                </NavLink>
              </>
            )}






          </SidebarMenu.Nav>
        </SidebarMenu.Body>
      </SidebarMenu>
    </>
  );
};
export default SideNav; 