import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { FaSearch } from "react-icons/fa";

const MyLeavesTable = () => {
  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(5);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [searchVal, setSearchVal] = useState("");

  useEffect(
    (currentPage) => {
      const getData = async () => {
        const res = await fetch(
          `http://jsonplaceholder.typicode.com/posts/?q=${searchVal}&?_page=${currentPage}&_limit=${limit}`
        );
        const data = await res.json();
        const total = res.headers.get("x-total-count");
        setTotalData(total);
        setpageCount(Math.ceil(total / limit));
        setItems(data);
      };
      getData();
    },
    [limit, searchVal]
  );

  const fetchData = async (currentPage) => {
    const res = await fetch(
      `http://jsonplaceholder.typicode.com/posts?_page=${currentPage}&_limit=${limit}`
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    const myData = await fetchData(currentPage);
    setItems(myData);
  };
  const handleChange = (e) => {
    setLimit(e.target.value);
  };
  // search data in api using search box
  const hangleSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">Leaves Summary</h4>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div>
            <label className="me-3">Show</label>
            <select
              className="selectdata  mb-3 me-2"
              value={limit}
              onChange={handleChange}
            >
              <option value="05">05</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
            <label className="me-3">Entries</label>
          </div>
          <div className="position-relative mb-4">
            <input
              type="search"
              placeholder="Search...."
              className="dashboard-inputs searchInp"
              onChange={hangleSearchVal}
            />
            <FaSearch className="primary-input-icon position-absolute" />
          </div>
        </div>
        <div className="customTable2 w-100 table-responsive">
          <table className="w-100">
            <thead>
              <tr>
                <th>#ID</th>
                <th>Leave Type</th>
                <th>From</th>
                <th>To</th>
                <th>Days</th>
                <th>Reason</th>
                <th>Applied On</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {items == "" ? (
                <tr>
                  <td colSpan="8">
                    <p className="my-2 smText text-decoration-underline text-danger">
                      No Record Found
                    </p>
                  </td>
                </tr>
              ) : (
                items.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.title.slice(0, 5)}</td>
                      <td>{item.title.slice(0, 5)}</td>
                      <td>{item.title.slice(0, 5)}</td>
                      <td>{item.title.slice(0, 5)}</td>
                      <td>{item.title.slice(0, 5)}</td>
                      <td>{item.title.slice(0, 5)}</td>
                      <td>
                        <span
                          className={
                            item.id == 1
                              ? "customBadge bgBlue"
                              : item.id == 2
                                ? "customBadge bgRed"
                                : item.id == 3
                                  ? "customBadge bgGreen"
                                  : "customBadge bgOrange"
                          }
                        >
                          {item.title.slice(0, 3)}
                        </span>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div className="d-flex align-items-center justify-content-between my-4">
            <p className="smText colBlack flex-shrink-0 me-3  my-2">
              {`Showing 1 to ${limit} of ${totalData} entries`}
            </p>
            <div className="customPagination my-2">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyLeavesTable;
