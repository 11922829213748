export const showHrPortal = (portal) => {
    return {
        type: "showHrPortal",
        payload: portal
    }
}

export const showMyPortal = (portal) => {
    return {
        type: "showMyPortal",
        payload: portal
    }
}
