import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaLaptopCode } from "react-icons/fa";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { toast } from "react-toastify";
import Title from "../../hook/Title";
import { verificationRoute } from "../../utils/APIRoutes";
import axios from "axios";
const Verify = () => {

  Title("Verify Code");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { email } = useParams();
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  // ---- States of inputs in the form of key value pair name:value ------ // 
  const [values, setValues] = useState({ code: "" });
  // ---- Getting values of inputs ------ //  
  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  // ---- Validate Form ------ //
  const validateForm = () => {
    const { code } = values;
    if (code === "") {
      toast.error("Verification Code is required ! ", toastOptions, toastOptions['position'] = "bottom-right");
      setIsLoading(false);
      return false;
    } else {
      return true;
    }
  };
  // ---- Form Submittion Function ------ // 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const { code } = values;
      const { data } = await axios.post(verificationRoute, {
        email, code
      });
      if (data.statusText === "success") {
        toast.success("Token Validated Successfully", toastOptions, toastOptions['position'] = "top-right");
        navigate(`/set-password/${email}`);
        setIsLoading(false);
      } else {
        toast.error(data.message, toastOptions, toastOptions['position'] = "top-left");
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <section className="authWrapper">
        <div className="authBox">
          <div className="text-center">
            <h2 className="primHeading ">VERIFICATION CODE</h2>
            <h6 className="smText">
              An email has been sent to you with a verification code.
              <br /> Please enter it here.
            </h6>
          </div>
          <form onSubmit={(event) => handleSubmit(event)} className="mt-5">
            <div className="registration-inp-box my-3">
              <label className="d-block" htmlFor="code">
                OTP<span className="text-danger">*</span>
              </label>
              <div className="position-relative">
                <button
                  className="position-absolute show-password"
                  type="button">
                  <FaLaptopCode />
                </button>
                <input
                  name="code"
                  id="code"
                  type="number"
                  placeholder="Verification Code"
                  className="primary-inputs"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="text-center mt-4">
              <button
                className="main-btn w-100"
                type="submit"
                disabled={isLoading}>
                {isLoading ? <LoadingSpinner /> : "Continue"}
              </button>
              <Link className="d-block mt-3 smText colBlack primLink" to="/">
                BACK TO LOGIN
              </Link>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
export default Verify;
