import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import Title from "../../hook/Title";
import GoBack from "../../Components/GoBack";

const name = localStorage.getItem("username");
const email = localStorage.getItem("email");
const EditProfile = () => {
  Title("Edit Profile");
  return (
    <>
      <div className="box">
        <div className="d-flex align-items-center mb-3">
          <GoBack />
          <h4 className="section-heading mb-0">Edit Profile</h4>
        </div>
        <Row>
          <Col xl={8} lg={10}>
            <div className="avatarWrap">
              <img
                src={require("../../assets/images/avatar.png")}
                alt="avatar"
                className="profileAvatar img-fluid"
              />
            </div>
            <Row className="mt-5">
              <Col md={6}>
                <div className="registration-inp-box mb-4">
                  <label htmlFor="name" className="d-block">
                    Name
                  </label>
                  <input
                    id="name"
                    className="primary-inputs px-3"
                    type="text"
                    placeholder="Name"
                    value={name}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="registration-inp-box mb-4">
                  <label htmlFor="number" className="d-block">
                    Number
                  </label>
                  <input
                    id="number"
                    className="primary-inputs px-3"
                    type="number"
                    placeholder="My Number"
                    value="0147852369"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="registration-inp-box mb-4">
                  <label htmlFor="emailAddress" className="d-block">
                    Email Address
                  </label>
                  <input
                    id="emailAddress"
                    className="primary-inputs px-3"
                    type="text"
                    placeholder="Email Address"
                    value={email}
                  />
                </div>
              </Col>
            </Row>
            <button className="main-btn d-inline-block">Update</button>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default EditProfile;
