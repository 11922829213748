import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const LeavesTable = () => {
  return (
    <>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 me-2">Leave Balance</h4> 
        <div className="customTable table-responsive">
          <table className="table mb-0 text-nowrap">
            <thead className="border-top">
              <tr>
                <th>Balance</th>
                <th>Used</th>
                <th>Available</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="d-flex align-items-center">
                  <span className="bgRed tdLabel rounded-circle"></span>
                  <span className="text-bold">Sick Leave</span>
                </td>
                <td className="">4.5</td>
                <td className="">16</td>
              </tr>
              <tr>
                <td className="d-flex align-items-center">
                  <span className="bgGreen tdLabel rounded-circle"></span>
                  <span className="text-bold">casual Leaves</span>
                </td>
                <td className="">5</td>
                <td className="">360</td>
              </tr>
              <tr>
                <td className="d-flex align-items-center">
                  <span className="bgOrange tdLabel rounded-circle"></span>
                  <span className="text-bold">Annual Leaves</span>
                </td>
                <td className="">5</td>
                <td className="">360</td>
              </tr>
              <tr>
                <td className="d-flex align-items-center">
                  <span className="bgBlue tdLabel rounded-circle"></span>
                  <span className="text-bold">Work from Home</span>
                </td>
                <td className="">8</td>
                <td className="">22</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Row>
          <Col className="text-center">
            <h4 className="subHeading">Vacations</h4>
            <h6 className="colBlue">
              8 <span className="colGray">/</span> 16
            </h6>
          </Col>
          <Col className="text-center borderCenter">
            <h4 className="subHeading">Sick leave</h4>
            <h6 className="colRed">
              4.5 <span className="colGray">/</span> 10
            </h6>
          </Col>
          <Col className="text-center">
            <h4 className="subHeading">Unpaid leave</h4>
            <h6 className="colBlack">
              5 <span className="colGray">/</span> 365
            </h6>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LeavesTable;
