import React, { useEffect } from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import { Row, Col, Container } from "react-bootstrap";
import Footer from "./Footer";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {

  const [myClass, setMyClass] = useState(true);
  const [mobMenuOption, setMobMenuOption] = useState(false);

  const location = useLocation();
  function toggleSideNav() {
    setMyClass(!myClass);
    if (!mobMenuOption) {
      setMyClass(false);
    }
    setMobMenuOption(true);
  }

  useEffect(() => {
    setMobMenuOption(false);
  }, [location])

  return (
    <>
      <div className={`siteWrapper ${myClass ? "" : "removeSideBar"}`}>
        <Container fluid className={`g-0 ${mobMenuOption ? "" : "mobileFunctionalNav"}`}>
          <Row>
            <Col>
              <div className="topBar">
                <Header toggleSideNav={toggleSideNav} />
              </div>
            </Col>
          </Row>
          <div className="d-flex mainContent">
            <div className="sideNav">
              <SideNav />
            </div>
            <div className="configuration">
              <Container fluid>{children}</Container>
              <Footer />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
// export default memo(Layout);
export default Layout;
