import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const Index = () => {
  const data = { 
    labels: [
      "Remaining Leaves",
      "Sick Leaves",
      "Casual Leaves",
      // "Gifted Leaves",
    ],
    datasets: [
      {
        label: "Leaves",
        data: [12, 19, 3],
        backgroundColor: [
          "rgb(1, 195, 83)",
          "rgb(51, 102, 255)",
          "rgb(247, 40, 74)",
          // "rgb(254, 127, 0)",
        ],
        // borderColor: [
        //   "rgba(255, 99, 132, 1)",
        //   "rgba(54, 162, 235, 1)",
        //   "rgba(255, 206, 86, 1)",
        //   "rgba(75, 192, 192, 1)",
        // ],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div>
      <Doughnut data={data} />
    </div>
  );
};

export default Index;
