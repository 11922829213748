import { Button } from 'bootstrap'
import React from 'react'
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const navigate = useNavigate();
  return (
    <div className='box m-md-5 m-2 p-md-5 p-2 text-center'>
      <h1 className='my-2'> Error  </h1>
      <h2 className='my-2'> Page Not Found  </h2>
      <button className='main-btn my-2' onClick={() => navigate(-1)}> Go Back </button>
    </div>
  )
}

export default Index