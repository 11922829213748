import Title from "../../hook/Title";
import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { FaEllipsisV } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { TableCom } from "../../Components/TableCom";
const Index = () => {
  // Table Head Data
  const Thead = ["S.No", "Date", "Time In", "Time Out", "Hours", "Status", "Actions"]
  // =========== Modals Code ==========//
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // =======================================
  const attendanceData = [
    {
      days: 30,
      title: "Total Working Days",
    },
    {
      days: 28,
      title: "Present Days",
    },
    {
      days: 2,
      title: "Absent Days",
    },
    {
      days: 4,
      title: "Half Days",
    },
    {
      days: 8,
      title: "Late Days",
    },
    {
      days: 4,
      title: "Holidays",
    },
  ];
  const [thLength, setThlength] = useState(1);
  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(5);
  const [totalData, setTotalData] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const BASEURL = "http://jsonplaceholder.typicode.com/posts"
  useEffect(
    (currentPage) => {
      currentPage = currentPage ?? 0
      const getData = async () => {
        const res = await fetch(
          `${BASEURL}/?q=${searchVal}&?_page=${currentPage}&_limit=${limit}`
        );
        const data = await res.json();
        const total = res.headers.get("x-total-count");
        setTotalData(total);
        setpageCount(Math.ceil(total / limit));
        setItems(data);
      };
      getData();
    },
    [limit, searchVal]
  );
  useEffect(() => {
    setThlength(Thead.length);
  }, [thLength]);
  const fetchData = async (currentPage) => {
    const res = await fetch(
      `${BASEURL}?_page=${currentPage}&_limit=${limit}`
    );
    const data = await res.json();
    return data;
  };
  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    const myData = await fetchData(currentPage);
    setItems(myData);
  };
  const handleChange = (e) => {
    setLimit(e.target.value);
  };
  // search data in api using search box
  const hangleSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  Title("My Attendance");

  const discrepancyAddedTost = () => {
    toast.success("Discrepancy Submitted Successfully !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };


  return (
    <>
      <h4 className="primHeading my-2 mb-4">Attendance</h4>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">Days Overview This Month</h4>
        <div className="row">
          {attendanceData?.map(({ days, title }, index) => {
            return (
              <div className="col-lg-2 col-md-3 col-6" key={index}>
                <div className="attendenceAnalytics">
                  <span className="date_time bgGreen">
                    <span className="date fs-20">{days}</span>
                  </span>
                  <h6 className="my-2">{title}</h6>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="box my-3">
        <h4 className="sectionHeading my-2 mb-4">Attendance Overview</h4>
        {/* Filters start here */}
        <Row className="customFilters">
          <Col xl={2} lg={4} md={6} sm={6} className="my-2">
            <label className="text-bold" htmlFor="From">
              From:
            </label>
            <input className="prim-date" type="date" name="" id="From" />
          </Col>
          <Col xl={2} lg={4} md={6} sm={6} className="my-2">
            <label className="text-bold" htmlFor="To">
              To:
            </label>
            <input className="prim-date" type="date" name="" id="To" />
          </Col>
          <Col xl={3} lg={4} md={6} sm={6} className="my-2">
            <label className="text-bold" htmlFor="Month">
              Month:
            </label>
            <select className="filterSelect">
              <option>Select Monts</option>
              <option>Jan</option>
              <option>Feb</option>
              <option>March</option>
              <option>April</option>
              <option>May</option>
            </select>
          </Col>
          <Col xl={3} lg={4} md={6} sm={6} className="my-2">
            <label className="text-bold" htmlFor="Year">
              Year:
            </label>
            <select className="filterSelect">
              <option>Select Year</option>
              <option>2022</option>
              <option>2021</option>
              <option>2020</option>
              <option>2019</option>
              <option>2018</option>
            </select>
          </Col>
          <Col xl={2} lg={4} md={6} sm={6} className="my-2">
            <button className="main-btn w-100 mt-4">Search</button>
          </Col>
        </Row>
        {/* <hr /> */}
        {/* Table start here */} 
        <TableCom
          limit={limit}
          totalData={totalData}
          handleChange={handleChange}
          hangleSearchVal={hangleSearchVal}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
        >
          <div className="customTable2 w-100 table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  {
                    Thead?.map((th, index) => {
                      return (
                        <th key={index}>{th}</th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {items == "" || items == undefined || items == null ? (
                  <tr>
                    <td colSpan={thLength}>
                      <p className="my-2 smText text-decoration-underline text-danger">
                        No Record Found
                      </p>
                    </td>
                  </tr>
                ) : (
                  items.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.title.slice(0, 5)}</td>
                        <td>{item.title.slice(0, 5)}</td>
                        <td>{item.title.slice(0, 5)}</td>
                        <td>{item.title.slice(0, 5)}</td>
                        <td>
                          <span
                            className={
                              item.id == 1
                                ? "customBadge bgBlue"
                                : item.id == 2
                                  ? "customBadge bgRed"
                                  : item.id == 3
                                    ? "customBadge bgGreen"
                                    : "customBadge bgOrange"
                            }
                          >
                            {item.title.slice(0, 3)}
                          </span>
                        </td>
                        <td>
                          <Dropdown className="actionDropDown">
                            <Dropdown.Toggle className="customDropDown">
                              <FaEllipsisV className="colBlack" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="drop_item"
                                as={Link}
                                to="#_"
                                onClick={handleShow}
                                type="button"
                              >
                                Change Request
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </TableCom>
      </div>
      {/* ============== Modal Code Start ================ */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered={true}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalPrimHeading">Change Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="#">
            <div className="registration-inp-box my-3">
              <label className="d-block" htmlFor="date">
                Date
              </label>
              <input
                id="date"
                type="date"
                value="2022-11-25"
                className="primary-inputs px-3 my-1"
                disabled
              />
            </div>
            <div className="registration-inp-box my-3">
              <label className="d-block" htmlFor="timeIn">
                Time In
              </label>
              <input
                id="timeIn"
                type="time"
                className="primary-inputs px-3 my-1"
              />
            </div>
            <div className="registration-inp-box my-3">
              <label className="d-block" htmlFor="timeOut">
                Time Out
              </label>
              <input
                id="timeOut"
                type="time"
                className="primary-inputs px-3 my-1"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="sec-btn mx-2" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="main-btn mx-2"
            onClick={(event) => {
              handleClose();
              discrepancyAddedTost();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Index; 