import React from "react";
import {
  FaFeatherAlt,
  FaRegClipboard,
  FaBoxes,
  FaFileAlt,
} from "react-icons/fa";

import { Row, Col } from "react-bootstrap";
import Title from "../../hook/Title";
import { BarChartComp } from "./Charts";
import { Link } from "react-router-dom";
import UpComingEvents from "../../Components/UpComingEvents";
import LeavesTable from "./LeavesTable";
import { showHrPortal } from '../../actions/HrPortalAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const analytics = [
  {
    id: 1,
    title: "Total Attendance",
    detail: "162",
  },
  {
    id: 2,
    title: "Absent",
    detail: "12",
  },
  {
    id: 3,
    title: "Leaves",
    detail: "0",
  },
  {
    id: 4,
    title: "Work from Home",
    detail: "5",
  },
];
const Index = () => {
  Title("Dashboard");
  const disPatch = useDispatch();
  const navigate = useNavigate();
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { role } = AuthReducer.data;
  const handleHrPortal = () => {
    disPatch(showHrPortal("showHrPortal"))
    navigate("/hr-dashboard")
  }
  return (
    <>

      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <h4 className="primHeading mb-0 my-2"> Employee <span>Dashboard</span> </h4>
        {
          role === "hr" ? (
            <div className="text-end">
              <button className="sec-btn mb-3" onClick={handleHrPortal}>View Hr Dashboard</button>
            </div>
          ) : <Link to="/applyLeaves" className="main-btn  my-2"> Apply Leaves </Link>
        }
      </div>
      <Row>
        {analytics.map((item, index) => {
          return (
            <Col
              key={index}
              xl={3}
              lg={6}
              sm={6}
              className={`analyticsWrap${item.id} mb-2`}
            >
              <div className="dashboard-anatlytics d-flex align-items-center justify-content-between flex-wrap">
                <div>
                  <h3>{item.title}</h3>
                  <h4>{item.detail}</h4>
                </div>
                <div className={`analyticsIcon${item.id} analyticsIcon`}>
                  {item.id === 1 ? (
                    <FaFileAlt className="icons" />
                  ) : item.id === 2 ? (
                    <FaBoxes className="icons" />
                  ) : item.id === 3 ? (
                    <FaRegClipboard className="icons" />
                  ) : item.id === 4 ? (
                    <FaFeatherAlt className="icons" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <div className="box my-3">
        {/* --------------------------  Attendance chart ----------------------------- */}
        <div className="d-flex align-items-center flex-wrap justify-content-between">
          <h4 className="sectionHeading mb-3">Attendance chart</h4>
          <div className="d-flex align-items-center justify-content-between my-2 flex-wrap">
            <div className="chartLabels m-2">
              <span className="bgBlue"></span>
              <p className="mb-0">Attendance</p>
            </div>
            <div className="chartLabels m-2">
              <span className="bgLgGray"></span>
              <p className="mb-0">Leaves</p>
            </div>
            <select className="dashboard-inputs m-2">
              <option>Year</option>
              <option>2022</option>
              <option>2021</option>
              <option>2020</option>
              <option>2019</option>
              <option>2018</option>
            </select>
          </div>
        </div>
        <BarChartComp />
        {/* -------------------------------------------------------------------- */}
      </div>
      <Row>
        <Col xl={5} lg={6} className="my-lg-0 my-3">
          <UpComingEvents />
        </Col>
        <Col xl={7} lg={6}>
          <LeavesTable />
        </Col>
      </Row>
    </>
  );
};
export default Index;
