import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import ReactPaginate from "react-paginate";
// let PageSize = 5;

const Index = () => {
  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(5);
  const [pageCount, setpageCount] = useState(0);
  // let limit = 5; 
  useEffect((currentPage) => {
    const getData = async () => {
      const res = await fetch(
        `http://jsonplaceholder.typicode.com/posts?_page=${currentPage}&_limit=${limit}`
      );
      const data = await res.json();
      const total = res.headers.get("x-total-count");
      setpageCount(Math.ceil(total / limit));
      setItems(data);
    };
    getData();
  }, [limit]);

  const fetchData = async (currentPage) => {
    const res = await fetch(
      `http://jsonplaceholder.typicode.com/posts?_page=${currentPage}&_limit=${limit}`
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    const myData = await fetchData(currentPage);
    setItems(myData);
  };
  const handleChange = (e) => {
    setLimit(e.target.value);
  };
const setSearchval=()=>{
  
}
  return (
    <>
      <Row>
        <Col className="mb-5">
          <div className="d-md-flex align-items-center">
            <label className="me-3 mt-4">Sort By</label>
            <div className="me-2">
              <label className="d-block me-2">From</label>
              <input type="date" className="dashboard-inputs mr-md-3" />
            </div>
            <div>
              <label className="d-block">To</label>
              <input type="date" className="dashboard-inputs" />
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-md-flex justify-content-between align-items-center">
        <div>
          <label className="me-3">Show Enteries</label>
          <select className="primSelect dashboard-inputs mb-4">
            <option>Default select</option>
            <option>select</option>
            <option>select</option>
            <option>select</option>
          </select>
        </div>
        <div className="dashboard-input-box position-relative mb-4">
          
          <input
            type="search"
            placeholder="Search...."
            className="dashboard-inputs w-100"
            onChange={(e) => setSearchval(e.target.value)}
          /> 
         
          <FaSearch className="primary-input-icon position-absolute" />
        </div>
      </div>
      <table className='customTable w-100 table-responsive'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Description</th>
          </tr>
        </thead> 
        <tbody>
          {
            items == '' ?
              <tr>
                <td>
                  No Record Found
                </td>
              </tr>
              : 
              items.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.title}</td>
                    <td>{item.title}</td>
                  </tr>
                )
              })

          }
        </tbody>
      </table>
      <div className='d-flex align-items-center justify-content-between flex-wrap'>
        <select className='selectdata  mb-3 me-2' value={limit} onChange={handleChange}>
          <option value='05'>05</option>
          <option value='10'>10</option>
          <option value='15'>15</option>
          <option value='20'>20</option>
        </select>
        <div className='customPagination mb-3'>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div> 
    </>
  );
}
export default Index;