import React from "react";
import { Modal } from "react-bootstrap";

const CustomModal = (props) => {
  return (
    <Modal
      {...props}
      backdrop="static"
      keyboard={false} 
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {props.children}
    </Modal>
  );
};

export default CustomModal;
