import React, { useState } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { changePasswordRoute } from "../../utils/APIRoutes";
import Title from "../../hook/Title";
import axios from "axios";
const SetPassword = () => {
  Title("Set Password");
  const navigate = useNavigate();
  const { email } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  let toastOptions = {
    position: "top-left",
    autoClose: 5000,
    pauseOnHover: false,
    draggable: false,
    theme: "dark",
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };
  // ---- States of inputs in the form of key value pair name:value ------ // 
  const [values, setValues] = useState({ password: "", cpassword: "" });
  // ---- Getting values of Inputs ------ //  
  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  // ---- Validate Form ------ //
  const validateForm = () => {
    const { password, cpassword } = values;
    if (password === "" || cpassword === "") {
      toast.error("Password & confirm Password Must be Required ! ", toastOptions, toastOptions['position'] = "bottom-right");
      setIsLoading(false);
      return false;
    } else if (password.length < 5) {
      toast.error("Password should be equal or greater than 5 characters.", toastOptions, toastOptions['position'] = "bottom-right");
      setIsLoading(false);
      return false;
    } else if (password !== cpassword) {
      toast.error("Password & confirm Password Must be Same ! ", toastOptions, toastOptions['position'] = "bottom-right");
      setIsLoading(false);
    } else {
      return true;
    }
  };
  // ---- Form Submittion Function ------ // 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const { password } = values;
      const { data } = await axios.post(changePasswordRoute, {
        email,
        password,
      }); 
      if (data.statusType === "success") {
        toast.success(data.message, toastOptions, toastOptions['position'] = "top-right");
        navigate("/");
        setIsLoading(false);
      } else {
        toast.error("something went wrong please try again later", toastOptions, toastOptions['position'] = "top-left");
        setIsLoading(false);
      }
    }
  };

  return (
    <section className="authWrapper">
      <div className="authBox">
        <div className="text-center">
          <h2 className="primHeading">Set New Password</h2>
          <h6 className="smText">Enter New Password</h6>
        </div>
        <form onSubmit={(event) => handleSubmit(event)} className=" mt-5">
          <div className="registration-inp-box my-3">
            <label className="d-block" htmlFor="password">
              Password<span className="text-danger">*</span>
            </label>
            <div className="position-relative">
              <input
                id="password"
                className="primary-inputs"
                type={passwordShown ? "text" : "password"}
                placeholder="Enter password"
                name="password"
                onChange={(e) => handleChange(e)} />
              <button
                className="position-absolute show-password"
                type="button"
                onClick={togglePassword}>
                {passwordShown ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>
          </div>
          <div className="registration-inp-box my-3">
            <label className="d-block" htmlFor="cpassword">
              Confirm Password<span className="text-danger">*</span>
            </label>
            <div className="position-relative">
              <input
                id="cpassword"
                className="primary-inputs"
                type={passwordShown2 ? "text" : "password"}
                placeholder="Re-Enter password"
                name="cpassword"
                onChange={(e) => handleChange(e)} />
              <button
                className="position-absolute show-password"
                type="button"
                onClick={togglePassword2}>
                {passwordShown2 ? <FaEye /> : <FaEyeSlash />}
              </button>
            </div>
          </div>
          <div className="text-center mt-4">
            <button
              className="main-btn w-100"
              type="submit"
              disabled={isLoading}>
              {isLoading ? <LoadingSpinner /> : "Update"}
            </button>
            <Link className="d-block mt-3 smText colBlack primLink" to="/">
              BACK TO LOGIN
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
};
export default SetPassword;
