import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import LeavesTable from "../Dashboard/LeavesTable";
import Calender from "../../Components/Calender";
import Title from "../../hook/Title";
const ApplyLeaves = () => {
  // Dynamic Page title
  Title("Apply Leaves");

  // Get value of leaves selected single / multiple
  const [optVal, setOptVal] = useState("single");
  const handleOptions = (e) => {
    setOptVal(e.target.value);
  };
  // Toast msg for after leave submitted
  const leaveAppliedTost = () => {
    toast.success("Leave Applied Successfully !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };
  return (
    <>
      <h4 className="primHeading my-2 mb-4">Apply Leaves</h4>
      <Row>
        <Col xxl={4} xl={6}>
          <div className="box my-3">
            <h4 className="sectionHeading my-2 mb-4">Apply Leaves</h4>
            <form action="#" method="post">
              <div className="my-3">
                <label className="text-bold" htmlFor="date">
                  Leaves Dates:
                </label>
                <select
                  className="filterSelect"
                  id="date"
                  onChange={handleOptions}
                  value={optVal}
                >
                  <option disabled>Select Option</option>
                  <option value="single">Single Leave</option>
                  <option value="multiple">Multiple Leaves</option>
                </select>
              </div>
              {/* ------ Conditional Rendering depends user selected option value  -------- */}
              {optVal == "multiple" ? (
                <div className="my-3">
                  <label className="text-bold" htmlFor="date">
                    Date Range:
                  </label>
                  <div className="row">
                    <div className="col">
                      <input
                        type="date"
                        name=""
                        className="filterSelect my-2"
                        id=""
                      />
                    </div>
                    <div className="col">
                      <input
                        type="date"
                        name=""
                        className="filterSelect my-2"
                        id=""
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="my-3">
                  <label className="text-bold" htmlFor="date">
                    Date:
                  </label>
                  <input type="date" name="" className="filterSelect" id="" />
                </div>
              )}
              {/* ---------------------------------- */}
              <div className="my-3">
                <label className="text-bold" htmlFor="leavesType">
                  Leaves Types:
                </label>
                <select className="filterSelect" id="leavesType">
                  <option disabled>Select Option</option>
                  <option>Half Day Leave</option>
                  <option>Casual Leaves</option>
                  <option>Sick Leaves</option>
                  <option>Maternity Leaves</option>
                  <option>Paternity Leaves</option>
                  <option>Annual Leaves</option>
                  <option>Unpaid Leaves</option>
                  <option>Work From Home</option>
                  <option>Other Leaves</option>
                </select>
              </div>
              <div className="my-3">
                <label className="text-bold" htmlFor="reason">
                  Reason:
                </label>
                <textarea
                  name=""
                  id="reason"
                  className="filterSelect"
                  rows="2"
                  placeholder="Some text here..."
                ></textarea>
              </div>
              <button
                type="button"
                className="main-btn my-3 w-100"
                onClick={leaveAppliedTost}
              >
                Submit
              </button>
            </form>
          </div>
        </Col>
        <Col xxl={4} lg={6}>
          <div className="box my-3">
            <h4 className="sectionHeading my-2 mb-4">Calendar</h4>
            <Calender />
          </div>
        </Col>
        <Col xxl={4} lg={6}>
          <LeavesTable />
        </Col>
      </Row>
    </>
  );
};

export default ApplyLeaves;
