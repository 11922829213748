import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  Label,
  BarChart,
  Bar,
  AreaChart,
  Area,
} from "recharts";


const pdata = [
  {
    year: "2011",
    noOFYears: 13,
    Earnings: 10,
    month: "January",
  },
  {
    year: "2012",
    noOFYears: 15,
    Earnings: 12,
    month: "February",
  },
  {
    year: "2013",
    noOFYears: 5,
    Earnings: 10,
    month: "March",
  },
  {
    year: "2014",
    noOFYears: 10,
    Earnings: 5,
    month: "April",
  },
  {
    year: "2015",
    noOFYears: 9,
    Earnings: 4,
    month: "May",
  },
  {
    year: "2016",
    noOFYears: 10,
    Earnings: 8,
    month: "June",
  },
  {
    year: "2017",
    noOFYears: 9,
    Earnings: 4,
    month: "July",
  },
  {
    year: "2018",
    noOFYears: 10,
    Earnings: 8,
    month: "August",
  },
  {
    year: "2019",
    noOFYears: 9,
    Earnings: 4,
    month: "September",
  },
  {
    year: "2020",
    noOFYears: 10,
    Earnings: 8,
    month: "October",
  },
  {
    year: "2021",
    noOFYears: 9,
    Earnings: 4,
    month: "November",
  },
  {
    year: "2022",
    noOFYears: 10,
    Earnings: 8,
    month: "December",
  },
];

// const LineChartComp = () => {
//   return (
//     <>
//       <Container className="mb-5">
//         <Row>
//           <Col>
//             <ResponsiveContainer width="100%" aspect={3}>
//               <LineChart data={pdata}>
//                 <CartesianGrid />
//                 <XAxis dataKey="year" interval={"preserveStartEnd"}>
//                   <Label
//                     value="No. of years"
//                     offset={0}
//                     position="insideBottom"
//                   />
//                 </XAxis>
//                 <YAxis />
//                 <Tooltip />
//                 <Legend />
//                 <Line dataKey="noOFYears" stroke="#f33f53"></Line>
//                 <Line dataKey="Earnings" stroke="green"></Line>
//               </LineChart>
//             </ResponsiveContainer>
//           </Col>
//         </Row>
//       </Container>
//     </>
//   );
// };
const BarChartComp = () => {
  return (
    <>
      <ResponsiveContainer aspect={4}>
        <BarChart data={pdata}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          <Bar dataKey="noOFYears" fill="#3366ff" />
          <Bar dataKey="Earnings" fill="#d6e0ff" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
// const AreaChartComp = () => {
//   return (
//     <>
//       <Container>
//         <Row>
//           <Col>
//             <ResponsiveContainer width="100%" aspect={3}>
//               <AreaChart data={pdata}>
//                 <defs>
//                   <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="5%" stopColor="#f33f53" stopOpacity={0.8} />
//                     <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
//                   </linearGradient>
//                   <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
//                     <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
//                   </linearGradient>
//                 </defs>
//                 <XAxis dataKey="stdName" />
//                 <YAxis />
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <Tooltip />
//                 <Legend />
//                 <Area
//                   type="monotone"
//                   dataKey="noOFYears"
//                   stroke="#f33f53"
//                   fillOpacity={1}
//                   fill="url(#colorUv)"
//                 />
//                 <Area
//                   type="monotone"
//                   dataKey="Earnings"
//                   stroke="#82ca9d"
//                   fillOpacity={1}
//                   fill="url(#colorPv)"
//                 />
//               </AreaChart>
//             </ResponsiveContainer>
//           </Col>
//         </Row>
//       </Container>
//     </>
//   );
// };

// export { LineChartComp, BarChartComp, AreaChartComp };
export { BarChartComp };
