import React from "react";
const UpComingEvents = () => {
  const holidaysData = [
    {
      date: "2 Jan",
      day: "Sunday",
      title: "Office Off",
      details: "29 days to left",
    },
    {
      date: "3 FEB",
      day: "Sunday",
      title: "Office Off",
      details: "3 days to left",
    },
    {
      date: "5 Dec",
      day: "Friday",
      title: "Public Holiday",
      details: "13 days to left",
    },
    {
      date: "8 Sep",
      day: "Wednesday",
      title: "Optional Holiday",
      details: "24 days to left",
    },
    {
      date: "24 Oct",
      day: "Sunday",
      title: "Conference",
      details: "5 days to left",
    },
    {
      date: "3 FEB",
      day: "Saturday",
      title: "Office Off",
      details: "8 days to left",
    },
  ];
  return (
    <>
      <div className="box my-3">
        <h4 className="sectionHeading mb-4">Announcements</h4>
        <ul className="comming_holidays">
          {holidaysData?.map((currentElem, index) => {
            return (
              <li
                key={index}
                className="mb-3 d-flex align-items-center justify-content-between"
              >
                <div className="d-flex align-items-center">
                  <span className="date_time me-3 bgGreen">
                    <span className="date fs-20">
                      {currentElem.date.slice(0, 2)}
                    </span>
                    <span className="month fs-13">
                      {currentElem.date.slice(2, 5)}
                    </span>
                  </span>
                  <div className="me-3">
                    <h6 className="mb-1">{currentElem.title}</h6>
                    <small>{currentElem.day}</small>
                  </div>
                </div>
                <p className="mb-0">{currentElem.details}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}; 
export default UpComingEvents;
