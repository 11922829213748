import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Title from "../../hook/Title";
import GoBack from "../../Components/GoBack";

const name = localStorage.getItem("username");
const email = localStorage.getItem("email");
const MyProfile = () => {
  Title("My Profile");
  return (
    <>
      <div className="box">
        <div className="d-flex align-items-center mb-3">
          <GoBack />
          <h4 className="section-heading mb-0">Profile</h4>
        </div>
        <Row>
          <Col xl={8} lg={10}>
            <div className="avatarWrap">
              <img
                src={require("../../assets/images/avatar.png")}
                alt="avatar"
                className="profileAvatar img-fluid"
              />
            </div>
            <Row className="mt-5">
              <Col md={6}>
                <div className="label-wrap mb-4">
                  <label htmlFor="name" className="d-block">
                    Name
                  </label>
                  <input
                    id="name"
                    className="primary-inputs disableInputs"
                    type="text"
                    placeholder="Name"
                    disabled
                    value={name}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="label-wrap mb-4">
                  <label htmlFor="number" className="d-block">
                    Number
                  </label>
                  <input
                    id="number"
                    className="primary-inputs disableInputs"
                    type="number"
                    placeholder="My Number"
                    disabled
                    value="0147852369"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="label-wrap mb-4">
                  <label htmlFor="emailAddress" className="d-block">
                    Email Address
                  </label>
                  <input
                    id="emailAddress"
                    className="primary-inputs disableInputs"
                    type="text"
                    placeholder="Email Address"
                    disabled
                    value={email}
                  />
                </div>
              </Col>
            </Row>
            <Link to="/edit-profile" className="main-btn d-inline-block">
              Edit
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default MyProfile;
