import { React } from "react";
import Title from "../../hook/Title";
import { Link } from "react-router-dom";
import MyLeavesTable from "./MyLeavesTable";
import { Row, Col } from "react-bootstrap";
import LeavesChart from "../../Components/Charts/Index";
import LeavesTable from "../Dashboard/LeavesTable";
const Leaves = () => {
  Title("My Leaves");
  return (
    <>
      <h4 className="primHeading my-3">My Leaves</h4>
      <Row>
        <Col lg={8} md={9}>
          <MyLeavesTable />
        </Col>
        <Col lg={4} md={3}>
          <div className="box my-3">
            <h4 className="sectionHeading my-2 mb-4">Leaves Overview</h4>
            <LeavesChart />
          </div>
          {/* <LeavesTable /> */}
        </Col>
      </Row>
    </>
  );
};

export default Leaves;
