import React from "react";
import { Col, Row } from "react-bootstrap";
import GoToTop from "../hook/GoToTop";
const Footer = () => {
  return (
    <> 
      <footer className="footer">
        <Row className="align-items-center">
          <Col className="text-center">
            <p className="my-3">
              Copyright © 2022 <a target="_blank" href="https://zibbletech.com/"> Zibbletect Pvt Ltd. </a> All Rights Reserved.
            </p>
          </Col>
        </Row>
      </footer>
      <GoToTop />
    </>
  );
};

export default Footer;
