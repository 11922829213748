import React from 'react'
import { FaSearch } from "react-icons/fa";
import ReactPaginate from "react-paginate";


export const TableCom = ({ children, limit, handleChange, hangleSearchVal, totalData, pageCount, handlePageClick }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <label className="me-3">Show</label>
          <select
            className="selectdata  mb-3 me-2"
            value={limit}
            onChange={handleChange}
          >
            <option value="05">05</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
          </select>
          <label className="me-3">Entries</label>
        </div>
        <div className="position-relative mb-4">
          <input
            type="search"
            placeholder="Search...."
            className="dashboard-inputs searchInp"
            onChange={hangleSearchVal}
          />
          <FaSearch className="primary-input-icon position-absolute" />
        </div>
      </div>
      {children}
      <div className="d-flex align-items-center justify-content-between my-4 flex-wrap table-responsive">
        <p className="smText colBlack flex-shrink-0 me-3  my-2">
          {`Showing 1 to ${limit} of ${totalData} entries`}
        </p>
        <div className="customPagination my-2">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </>
  )
}
